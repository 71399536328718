import React, { useEffect } from "react"

import Layout from "../components/layout"

import Form from "../components/form"
import classes from "./contact.module.scss"

import SEO from "../components/seo"
import { FormattedMessage, Link, useIntl } from "gatsby-plugin-intl"

const Contact = () => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "title--contact" })} lang={intl.locale} />
      <div className={classes.contact}>
        <h2
          style={{ textAlign: "center", gridColumn: "center-start/center-end" }}
        >
          <FormattedMessage id="contactHeading" />
        </h2>
        <div className={classes.contact__textbox}>
          <p className={classes.contact__textbox__text}>
          <FormattedMessage id="contactText" />
          </p>
          <div className={classes.contact__linkbox}>
            <ul className={classes.contact__linkbox__links}>
              <li className={classes.contact__linkbox__links__link}>
                <a
                  href="https://www.facebook.com/pawelstyczendesign/"
                  target="blank"
                >
                  <img src={require("../images/facebook.svg")}></img>
                </a>
              </li>
              <li className={classes.contact__linkbox__links__link}>
                {" "}
                <a
                  href="https://www.instagram.com/pawelstyczenuiux/"
                  target="blank"
                >
                  <img src={require("../images/instagram.svg")}></img>
                </a>
              </li>
              <li className={classes.contact__linkbox__links__link}>
                {" "}
                <a
                  href="https://www.linkedin.com/in/paweł-styczeń-12aa93147"
                  target="blank"
                >
                  <img src={require("../images/linkedin.svg")}></img>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <Form></Form>
      </div>
    </Layout>
  )
}

export default Contact
