import React, { useEffect } from "react"
import classes from "./form.module.scss"
import { FormattedMessage, Link, useIntl } from "gatsby-plugin-intl"

const Form = () => {
  return (
    <form
      name="contact"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input
        type="hidden"
        name="form-name"
        value="contact"
        className="animate"
      />

      <div className={classes.item}>
        <label htmlFor="name"><FormattedMessage id="form--name" /></label>
        <input
          type="text"
          name="name"
          className="animate"
          data-animation="form"
        ></input>
      </div>

      <div className={classes.item}>
        <label htmlFor="email"><FormattedMessage id="form--email" /></label>
        <input
          type="email"
          name="email"
          className="animate"
          data-animation="form"
        ></input>
      </div>

      <div className={classes.item}>
        <label htmlFor="message"><FormattedMessage id="form--message" /></label>
        <textarea
          name="message"
          className="animate"
          data-animation="form"
        ></textarea>
      </div>

      <button
        className={`${"animate"} ${"btn--send"} `}
        type="submit"
        data-animation="neo-background-btn--send"
      >
        <FormattedMessage id="btn--send" />
      </button>
    </form>
  )
}

export default Form
